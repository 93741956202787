/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { jsx, SxStyleProp } from 'theme-ui';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { GatsbyLinkProps } from 'gatsby';
import { AnchorHTMLAttributes } from 'react';

// Extend the properties with Theme UI's SxStyleProp
export interface UAnchorLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement>, GatsbyLinkProps<any> {
  to: string;
  sx?: SxStyleProp;
}

export const UAnchorLink: React.FC<UAnchorLinkProps> = ({ children, to, sx, ...props }) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const hashIndex = to.indexOf('#');
      const selector = hashIndex !== -1 ? to.substring(hashIndex) : to;
      const section = document.querySelector(selector)!;
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      const sectionTop = section?.getBoundingClientRect().top + window.scrollY;
      const sectionBottom = sectionTop + section?.getBoundingClientRect().height;
      if (sectionTop <= scrollPosition && sectionBottom >= scrollPosition) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [to]);

  return (
    <AnchorLink
      to={to}
      {...props} // Spread additional props to support all native AnchorLinkProps
      className={`${props.className || ''} ${isActive ? 'active' : ''}`}
      sx={{
        ...sx,
        '&.active': { variant: 'links.active' }, // Apply active styles conditionally
      }}
    >
      {children}
    </AnchorLink>
  );
};
