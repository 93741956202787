import React from "react";
import { Flex, Box, Heading, Text } from "rebass";
import { companies, Company } from "./CompaniesData";
import { ULink } from "./ULink";

const CompanyProjects: React.FC<{ title: string }> = ({ title }) => {
  // Filter the companies array to find the company with the matching title
  const company = companies.find((company: Company) => company.title === title);

  // If no company is found, render a message
  if (!company) {
    return <div>Company not found</div>;
  }

  // Render the company details
  return (
    <section>
      <Flex flexWrap="wrap" justifyContent="space-between" variant="container">
        <Box width={[1 / 2]} mb={[0, 3]}>
          <Heading as="h3" variant="catHeading">
            More from
          </Heading>
        </Box>
        <Box width={[1 / 2]} mb={[0, 3]} textAlign="right">
          <Heading as="h3" variant="catHeading">
            <ULink
              to="/work/"
              activeClassName="active"
              sx={{
                position: "relative",
                zIndex: "1",
                //textDecoration: "underline"
              }}
            >
              All Projects &rarr;
            </ULink>
          </Heading>
        </Box>
        <Box variant="containerRelatedProject">
          <ULink
            to={company.page!}
            activeClassName="active"
            //partiallyActive={true}
            sx={{
              variant: "links.imgLink",
              display: "block",
              cursor: "pointer",
            }}
          >
            <Flex flexWrap={["wrap", "nowrap"]} py={[3]}>
              {/* Title */}
              <Box
                order={[0, 0, 0]}
                flex={["auto 0 0", "auto 0 0", "25ch 0 0"]}
              >
                <Heading
                  as="h2"
                  variant="paragraphXL"
                  fontWeight="heading"
                  mr={[4]}
                  mb={[2]}
                >
                  {company.title}
                </Heading>
              </Box>

              {/* Label + desc */}
              <Box
                order={[2, 0, 0]}
                flex={["100% 0 0", "1 0 0"]}
                alignItems="center"
                justifyItems="stretch"
              >
                {company.label ? (
                  <Text as="span" variant="projectLabel" ml={[0]} mb={[2]}>
                    {company.label}
                  </Text>
                ) : null}
                {company.tagline ? (
                  <Text as="p" mb={[2]}>
                    {company.tagline}
                  </Text>
                ) : null}
                {company.location || company.discipline ? (
                  <Box>
                    {company.discipline && (
                      <Text
                        as="span"
                        variant="caption"
                        sx={{ display: "inline-block" }}
                        py={[0, 0, 0]}
                      >
                        {company.discipline}
                      </Text>
                    )}
                    {company.location && (
                      <Text
                        as="span"
                        variant="caption"
                        sx={{ display: "inline-block" }}
                        py={[0, 0, 0]}
                      >
                        &nbsp; | &nbsp;{company.location}
                      </Text>
                    )}
                  </Box>
                ) : null}
              </Box>

              {/* Year */}
              <Box order={[1, 0, 0]} flex={["1 0 0"]} alignItems="flex-end">
                {company.year ? (
                  <Text as="p" textAlign="right" fontWeight="heading">
                    {company.year}
                  </Text>
                ) : null}
              </Box>
            </Flex>
          </ULink>
        </Box>

        {company.companyProjects && <company.companyProjects />}
      </Flex>
    </section>
  );
};

export default CompanyProjects;
