/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import { Fragment, useEffect } from "react";
import { UAnchorLink } from "../../../components/UAnchorLink";
import { Box, Flex, Heading, Text, Button } from "rebass";
import Hero from "../../../components/Hero";
import ProjectTitleListing from "../../../components/ProjectTitleListing";
import ProjectFadeInTitle from "../../../components/ProjectFadeInTitle";
import BackToTop from "../../../components/BackToTop";
import { StaticImage } from "gatsby-plugin-image";
import BookScene from "../../../components/BookScene";
import { Modal } from "../../../components/Modal";
import { useModal } from "../../../components/useModal";
import Carousel from "../../../components/Carousel";
import Close from "../../../icons/Close";
import CompanyProjects from "../../../components/CompanyProjects";
import Layout from "../../../layouts/";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import ThemeColorSetter from "../../../components/ThemeColorSetter";

//Assets
import img1 from "../../../img/bose/b-card-definition-and-anatomy.jpg";
import img2 from "../../../img/bose/b-card-mobile first.jpg";
import img3 from "../../../img/bose/b-card-eyebrow-label.jpg";
import img4 from "../../../img/bose/b-card-action-labels.jpg";
import img5 from "../../../img/bose/b-card-visual-hierachy.jpg";

//Videos
import SourceOfTruth from "../../../icons/SourceOfTruth";
import CustomerInsights from "../../../icons/CustomerInsights";

function ProjectBoseDS() {
  const context = useThemeUI();
  const { theme } = context;
  {
    /* const modalPersona = useModal(); */
  }
  const modalMobileFirst = useModal();

  let pathname = typeof window !== "undefined" ? window.location.pathname : "";
  const section1 = pathname + "#section1";
  const section2 = pathname + "#section2";
  const section3 = pathname + "#section3";
  const section4 = pathname + "#section4";

  const projectBgColor = theme?.colors?.boseProjectBgDs as string;
  const projectTextColor = theme?.colors?.boseProjectTextDs as string;
  const themeColor = theme?.colors?.boseProjectBgDs as string;
  const themeColorBg = theme?.colors?.background as string;

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.getElementById("hero-section");
      if (heroSection) {
        const rect = heroSection.getBoundingClientRect();
        if (rect.bottom > 0) {
          document.body.classList.add("hero-visible");
          document.body.classList.remove("hero-hidden");
        } else {
          document.body.classList.add("hero-hidden");
          document.body.classList.remove("hero-visible");
        }
      } else {
        document.body.classList.add("hero-hidden");
        document.body.classList.remove("hero-visible");
      }
    };

    const heroSection = document.getElementById("hero-section");
    if (heroSection) {
      window.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      document.body.classList.add("hero-hidden");
      document.body.classList.remove("hero-visible");
    }
  }, []);

  return (
    <Layout>
      <ThemeColorSetter color={themeColor} colorBg={themeColorBg} />
      <main>
        <Header bgColor={projectBgColor} textColor={projectTextColor} />
        {/* On page navigation */}
        <Flex justifyContent="center">
          <Box variant="onPageNav" className="page-nav">
            <UAnchorLink
              to={section1}
              sx={{
                variant: "buttons.outline",
                backgroundColor: "transparent",
              }}
              title="Overview"
            >
              Overview
            </UAnchorLink>
            <UAnchorLink
              to={section2}
              sx={{
                variant: "buttons.outline",
                backgroundColor: "transparent",
              }}
              title="Problem statement"
            >
              Problem
            </UAnchorLink>
            <UAnchorLink
              to={section3}
              sx={{
                variant: "buttons.outline",
                backgroundColor: "transparent",
              }}
              title="Approach"
            >
              Approach
            </UAnchorLink>
            <UAnchorLink
              to={section4}
              sx={{
                variant: "buttons.outline",
                backgroundColor: "transparent",
              }}
              title="Result"
            >
              Result
            </UAnchorLink>
          </Box>
        </Flex>

        {/* Hero / Overview */}
        <div id="section1">
          <Hero
            projectType={"Case study"}
            title={"Solid"}
            titleRow={"Foundations"}
            titleClassName={"uppercase"}
            productType={"eCommerce, Website, Design System"}
            subtitle={
              "Setting the foundations for an immersive shopping experience"
            }
            projectBg={projectBgColor}
            projectText={projectTextColor}
            year={"2022 — 2024"}
            role={<Fragment>Senior UX Designer</Fragment>}
            company={"BOSE"}
            roleDesc={
              <Fragment>
                I led and guided the creation of foundational elements for
                design systems, facilitating consistent and scalable design
                solutions. Additionally, I produced critical documentation to
                align design and development teams. Ensuring the quality of new
                implementations, I held demo sessions, conducted thorough
                reviews, and promptly addressed critical issues through
                effective escalation channels.
              </Fragment>
            }
            disciplines={
              <Fragment>
                Content &mdash; Design &mdash; Development &mdash; Marketing
              </Fragment>
            }
            challenge={
              <Fragment>
                Create a unifying digital design system to ensure consistency at
                scale. Centralize our understanding of the design system, making
                it fast and easy for everyone to use, and ensure consistency and
                clarity across all design and development efforts. Promote a
                shared mindset on how to work within the boundaries of a design
                system, transforming it into a powerful tool to create anything
                digital.
              </Fragment>
            }
            component={
              <BookScene
                coverColor={"#f0c844"}
                textColor={"silver"}
                urlTexture={"/models/textures/bose-book-cover.jpg"}
              />
            }
          />
        </div>

        {/* Problem statement */}
        <div id="section2">
          <Flex
            flexWrap="wrap"
            justifyContent="center"
            variant="container"
            id="project-content"
          >
            <Box width={[1 / 1, 1 / 1, 1 / 1]} variant="containerBigTitle">
              <ProjectFadeInTitle
                word1={"Multiple"}
                word2={"sources"}
                word2ClassName={"outline"}
                word3={"of"}
                word3ClassName={"outline"}
                word4={"truth"}
                className={"uppercase"}
              />
            </Box>

            <Box variant="containerTxt">
              <Heading as="h3" variant="catHeading">
                Problem statement
              </Heading>
              <Text
                as="p"
                mb={[5, 6]}
                variant="paragraphXL"
                fontWeight="medium"
              >
                Bose needed a way to consistently evolve the brand at scale in a
                fast-changing digital ecosystem to gain a competitive advantage
                and create emotionally engaging digital experiences for our
                customers.
              </Text>
              <Heading as="h3" variant="catHeading">
                Context
              </Heading>
              <Heading as="h2" variant="bigTitleXS">
                Identifying challenges
              </Heading>
              <Text as="p" mb={[5, 6]}>
                Our Figma library, rich with comments, served as a decision log
                to pinpoint recurring issues encountered by teams. As daily
                demands surged and requests multiplied, we faced the challenge
                of maintaining consistency across two distinct platforms.
              </Text>
              <Heading as="h3" variant="bigTitleXS">
                #1 Inconsistency
              </Heading>
              <Text as="p" mb={[3, 4]}>
                An initial audit of the design system components revealed
                misalignments among team members regarding changes and new
                proposals, highlighting the need to refine our processes and
                establish clear governance.
              </Text>
              <Box width={[1 / 1]} variant="wrapperImg">
                <StaticImage
                  src="../../../img/bose/b-ds-component-issues.jpg"
                  alt="Design System - Insonsistent vomponent naming convention"
                />
              </Box>
              <Text as="span" variant="caption" mb={[4, 5, 6]}>
                Inconsistent naming conventions across the same component, with
                configurable properties not clearly visible.
              </Text>

              <Heading as="h3" variant="bigTitleXS">
                #2 Lack of documentation
              </Heading>
              <Text as="p" mb={[5, 6]}>
                While addressing development changes and resolving issues, we
                discovered a significant disconnect between design, front-end,
                and authoring teams. Each team referred to different files in
                search of the source of truth, leading to confusion and
                inconsistency due to some unmaintained files and components.
              </Text>

              <Heading as="h3" variant="bigTitleXS">
                #3 No governance
              </Heading>
              <Text as="p">
                When there is no governance over a design system, several
                challenges can arise, often leading to inefficiency and
                inconsistency across an organization's projects.{" "}
                <ul>
                  <li>Inconsistency</li>
                  <li>Redundancy</li>
                  <li>Quality issues</li>
                  <li>Scalability problems</li>
                  <li>Adoption Barries</li>
                  <li>Decreased collaboration</li>
                </ul>
              </Text>
            </Box>
          </Flex>
        </div>

        <div id="section3">
          {/* Design System is a product */}
          <Flex
            flexWrap="wrap"
            py={[3, 4]}
            variant="container"
            justifyContent="center"
            mb={[4, 5]}
          >
            <Box width={[1 / 1, 1 / 1, 8 / 12]} variant="containerBigTitle">
              <ProjectFadeInTitle
                word1={"Design"}
                word2={"System"}
                word2ClassName={"outline"}
                word3={"is"}
                word3ClassName={"outline"}
                word4={"a Product"}
                className={"uppercase"}
              />
            </Box>
            <Box width={[1 / 1, 1 / 1, 4 / 12]} variant="containerBigTitleImg">
              <Heading as="h3" variant="catHeading">
                Design System
              </Heading>
              <Box maxWidth="700px" mx="auto">
                <CustomerInsights />
              </Box>
            </Box>

            <Box variant="containerTxt">
              <Heading as="h3" variant="catHeading">
                Solution
              </Heading>
              <Text as="p" pb={[3, 4]} mb={[5, 6]} variant="paragraphXL">
                A design system is a transformative{" "}
                <strong>way of working</strong>, a scalable resource, and the
                ultimate <strong>source of truth</strong>. Adopting such systems
                represents a significant cultural shift, and any resistance to
                this change must be thoughtfully addressed and managed.
              </Text>
              <Heading as="h3" variant="catHeading">
                Our goals
              </Heading>
              <Heading as="h3" variant="bigTitleXS">
                Setting ourselves up for success
              </Heading>
              <Text as="p" mb={[3, 4, 5]}>
                Our objective was to encourage both our internal teams, external
                vendors and agencies, to adopt and work within the boundaries of
                a design system. Recognizing that resistance to adopting new
                systems is common, we concentrated on establishing solid
                foundations that could adapt to our rapidly evolving business
                and increasingly diverse user base.
              </Text>

              <Box mb={[4, 5]}>
                <Flex flexWrap={["wrap", "nowrap"]} justifyContent="center">
                  <Box
                    width={["auto", 1 / 1, 1 / 2]}
                    p={[3, 4]}
                    variant="chatBubble"
                    mb={[4, 3]}
                    mr={[0, 2]}
                  >
                    <Text as="p">We need a cross-functional team</Text>
                  </Box>

                  <Box
                    width={["auto", 1 / 1, 1 / 2]}
                    p={[3, 4]}
                    variant="chatBubble"
                    mb={[4, 3]}
                    mx={[0, 2]}
                  >
                    <Text as="p">We need established rituals</Text>
                  </Box>

                  <Box
                    width={["auto", 1 / 1, 1 / 2]}
                    p={[3, 4]}
                    variant="chatBubble"
                    mb={[4, 3]}
                    ml={[0, 2]}
                  >
                    <Text as="p">We need accesible documentation</Text>
                  </Box>
                </Flex>
              </Box>
              <Text as="p" mb={[5, 6]}>
                <ul>
                  <li>Make it fast and easy to use for everyone, everywhere</li>
                  <li>
                    Create a platform for innovation and deeper engagement
                  </li>
                  <li>More consistency and more clarity</li>
                </ul>
              </Text>
            </Box>
          </Flex>

          <Flex
            flexWrap="wrap"
            py={[3, 4]}
            variant="container"
            justifyContent="center"
            mb={[4, 5]}
          >
            <Box width={[1 / 1, 1 / 1, 8 / 12]} variant="containerBigTitle">
              <ProjectFadeInTitle
                word1={"Single"}
                word2={"Source"}
                word2ClassName={"outline"}
                word3={"of"}
                word3ClassName={"outline"}
                word4={"Truth"}
                className={"uppercase"}
              />
            </Box>
            <Box width={[1 / 1, 1 / 1, 4 / 12]} variant="containerBigTitleImg">
              <Heading as="h3" variant="catHeading">
                Design System
              </Heading>
              <SourceOfTruth />
            </Box>
            <Box variant="containerTxt">
              <Heading as="h2" variant="bigTitleXS" mb={[3]}>
                A way of working
              </Heading>
              <Text as="p" pb={[3, 4]}>
                I’ve established a structured approach by organizing Figma files
                according to team roles:
                <ul>
                  <li>
                    <strong>Library (The Source of Truth)</strong> &mdash;
                    houses all in use design elements and components.
                  </li>
                  <li>
                    <strong>Template (Library in Use)</strong> &mdash; utilizes
                    the library within specific guidelines.
                  </li>
                  <li>
                    <strong>Working Files (Design Freedom)</strong> &mdash;
                    flexibility in using and adapting the design system.
                  </li>
                </ul>
                This clear structure enabled us to introdude a process using
                Figma branches for implementing changes and introducing new
                components. This approach not only maintained oversight but also
                provided a decision log. This log helps inform stakeholders
                about project timelines, capacity, and associated costs.
              </Text>
              <Box width={[1 / 1]} mb={[4, 5, 6]} variant="wrapperImg">
                <StaticImage
                  src="../../../img/bose/b-design-system-Figma-setup.jpg"
                  alt="Design System - Figma Setup"
                />
              </Box>

              <Heading as="h2" variant="bigTitleXS" mt={[3, 4, 5]}>
                Summary of achievements
              </Heading>
              <Text as="p" mb={[4, 5, 6]}>
                <ul>
                  <li>
                    <strong>Guidelines and recommendations</strong> &mdash; I
                    led the creation of guidelines and recommendations to
                    support our creative teams.
                  </li>
                  <li>
                    <strong>Design system and CMS integration</strong> &mdash;
                    guided the team to bridge the gap between the CMS and the
                    design system library to facilitate collaboration between
                    authoring and design.
                  </li>
                  <li>
                    <strong>Naming conventions</strong> &mdash; Standardized and
                    refined naming conventions, resolving inconsistencies to
                    boost the reusability of our assets.
                  </li>
                  <li>
                    <strong>Component Optimization</strong> &mdash; I’ve led the
                    efforts to provide structure and better performance of the
                    components in Figma and in development in order to ensure
                    the platform is scalable.
                  </li>
                  <li>
                    <strong>Quality assurance</strong> &mdash; Held demo
                    sessions, conducted in-depth reviews, and addressed critical
                    issues through effective escalation channels.
                  </li>
                </ul>
              </Text>

              {/* Card System */}
              <Heading as="h3" variant="catHeading">
                Highlight
              </Heading>
              <Heading as="h2" variant="bigTitleXS">
                Card System
              </Heading>
              <Text as="p" mb={[3, 4]}>
                Before &#8594; Desktop first
              </Text>

              <Box width={[1 / 1]} variant="wrapperImg">
                <StaticImage
                  src="../../../img/bose/b-card-system-before.jpg"
                  alt="Card System - Before"
                />
              </Box>
              <Text as="p" variant="caption" mb={[4]}>
                Issues with visual hierarchy made it difficult for users to
                quickly skim through information. Furthermore, the entire card
                not being clickable contributed to a high bounce rate and low
                click-through rates.
              </Text>

              <Text as="p" mb={[3, 4]}>
                After &#8594; Mobile first
              </Text>
              <Box width={[1 / 1]} variant="wrapperImg">
                <StaticImage
                  src="../../../img/bose/b-card-system-after.jpg"
                  alt="Card System - After"
                />
              </Box>
              <Text as="p" variant="caption" mb={[4, 5, 6]}>
                We improved the customer experience by implementing internal
                guidelines and recommendations, ensuring alignment between Figma
                and our CMS with consistent, configurable options.
              </Text>

              {/* Mobile first */}
              <Heading as="h2" variant="bigTitleXS">
                A mobile-first experience should never feel like a compromise
              </Heading>
              <Text as="p" mb={[2, 3, 4]}>
                To accommodate the fact that approximately{" "}
                <strong>
                  80% of our web traffic comes from mobile devices
                </strong>
                , it became imperative to adopt a mobile-first approach that
                transcends the notion of compromise. We aimed to move beyond a
                "good enough" mentality by integrating visual hierarchy and
                robust design system rules. This new strategy allows us to craft
                instantly impactful and deserving designs optimized for the
                mobile experience. <br />
                <br />
                <ul>
                  <li>Simplify by reducing moving parts.</li>
                  <li>Prioritize only important information and actions.</li>
                  <li>Responsive design is a must.</li>
                </ul>
              </Text>

              {/*ModalMobileFirst */}
              <Button
                mb={[5, 6]}
                sx={{ variant: "buttons.primary", fontSize: [2, 2] }}
                onClick={modalMobileFirst.toggle}
              >
                Learn more <Close />
              </Button>

              <Modal
                isShown={modalMobileFirst.isShown}
                hide={modalMobileFirst.toggle}
                headerText="Mobile first"
                modalContent={
                  <Fragment>
                    <Text as="p" pb={[3, 4]}>
                      Creating a mobile-first experience involves simplification
                      and strategic prioritization to enhance usability on
                      smaller devices.
                    </Text>
                    <Heading as="h3" variant="catHeading">
                      Golden Rules
                    </Heading>
                    <Text as="p">
                      <ul>
                        <li>
                          <strong>Simplify:</strong> <br /> Reduce the number of
                          moving parts to minimize distraction and enhance user
                          focus.
                        </li>
                        <li>
                          <strong>
                            Prioritize navigation, information and actions:
                          </strong>
                          <br /> Clearly define what's important, making screens
                          intuitive and straightforward.
                        </li>
                        <li>
                          <strong>Iterate on design fidelity:</strong>
                          <br /> Focus on refining design details over adding
                          new functionalities.
                        </li>
                        <li>
                          <strong>Use Patterns:</strong>
                          <br /> Start with established design patterns that are
                          known to work, and continuously test and refine them.
                        </li>
                        <li>
                          <strong>Highlight essential information:</strong>
                          <br /> Use progressive disclosure to present
                          information, revealing more details as needed.
                        </li>
                        <li>
                          <strong>Ensure responsiveness:</strong>
                          <br /> Design components that scale smoothly across
                          different screen sizes.
                        </li>
                        <li>
                          <strong>Adopt fluid typography:</strong>
                          <br /> Utilize flexible typography that adjusts well
                          to varying screen sizes.
                        </li>
                        <li>
                          <strong>Use relative units:</strong>
                          <br /> Employ CSS units like <code>rems</code> and
                          functions like <code>clamp()</code> for flexible
                          sizing.
                        </li>
                        <li>
                          <strong>Maintain aspect ratios:</strong>
                          <br /> Ensure that images and videos maintain their
                          aspect ratios to avoid distortion.
                        </li>
                        <li>
                          <strong>Avoid full-screen carousels:</strong> <br />
                          They can be cumbersome on mobile; opt for more
                          user-friendly alternatives.
                        </li>
                        <li>
                          <strong>Optimize content layout:</strong>
                          <br /> Make entries recognizable and easy to skim
                          through.
                        </li>
                        <li>
                          <strong>Enhance orientation and navigation:</strong>{" "}
                          <br />
                          Always indicate to users where they are within the app
                          or website and provide an easy way to return to
                          previous screens.
                        </li>
                        <li>
                          <strong>Keep it simple:</strong> Strive for minimalism
                          and clarity in every element of your design.
                        </li>
                      </ul>
                    </Text>
                  </Fragment>
                }
              />

              {/* Card System - Reduce complexity */}
              <Heading as="h3" variant="catHeading">
                Highlight
              </Heading>
              <Heading as="h2" variant="bigTitleXS">
                Reduce complexity
              </Heading>
              <Text as="p" mb={[3, 4]}>
                Before &#8594; 24 variants (for one component)
              </Text>
              <Box width={[1 / 1]} variant="wrapperImg">
                <StaticImage
                  src="../../../img/bose/b-card-system-reduce-complexity-before.jpg"
                  alt="Card System - Reduce complexity - Before"
                />
              </Box>
              <Text as="p" variant="caption" mb={[4]}>
                The example above illustrates the complexity of one component
                &mdash; eCommerce Card, which comprised 24 variants. Our card
                system had 11 cards in total, each with at least four variants.
                Adding the numbers up we ended up with ~300 variants.
                Maintenance and updates often required weeks to complete, if
                they were even done in the first place.
              </Text>

              <Text as="p" mb={[3, 4]}>
                After &#8594; 4 variants
              </Text>
              <Box width={[1 / 1]} variant="wrapperImg">
                <StaticImage
                  src="../../../img/bose/b-card-system-reduce-complexity-after.jpg"
                  alt="Card System - Reduce complexity - After"
                />
              </Box>
              <Text as="p" variant="caption" mb={[4, 5, 6]}>
                We enhanced the configurability of our card variants, making
                them agnostic to their usage contexts. Now, these cards can be
                utilized across multiple areas of the website by simply toggling
                options on and off, significantly increasing their versatility
                and ease of use.
              </Text>

              {/* Connecting the dots */}
              <Heading as="h2" variant="bigTitleXS">
                Connecting the dots
              </Heading>
              <Text as="p" mb={[4]}>
                Our cards, designed within various templates, provide customers
                with seamless access to information. However, frequent shifts in
                team members across different projects can create a disconnect
                between these components. To counter this, we stressed that each
                card is part of a bigger picture, focusing on consistency and
                familiarity to maintain a coherent user experience. <br />
                <br />
                Additionally, we equipped visual designers with multiple
                stylization options for cards, allowing the use of any feature
                artwork while also supporting content with landscape imagery or
                without any artwork, enhancing versatility across content types.
              </Text>
              <Box width={[1 / 1]} variant="wrapperImg">
                <StaticImage
                  src="../../../img/bose/b-card-system-mobile-first-flow.jpg"
                  alt="Card System - Mobile first - flow"
                />
              </Box>
              <Text as="span" variant="caption" mb={[4, 5, 6]}>
                Ensure consistent representation across the platform to help
                users understand their navigation path without confusion.
              </Text>
            </Box>
          </Flex>
        </div>

        <div id="section4">
          <Flex
            flexWrap="wrap"
            py={[3, 4]}
            variant="container"
            justifyContent="center"
            mb={[4, 5]}
          >
            <Box variant="containerTxt">
              <Heading as="h2" variant="bigTitleXS">
                Guidelines &amp; recommedations
              </Heading>
              <Text as="p" mb={[2, 3, 4]}>
                We've developed documentation to help cross-functional teams
                fully understand the system, enabling them to produce their best
                work within established limitations.
              </Text>
              <Heading as="h3" variant="catHeading">
                Note
              </Heading>
              <Text as="p">
                It goes beyond just colors, typography, and UI buttons. The
                focus is on the relationships between Figma, CMS, and the
                e-commerce platform, viewing our design efforts holistically to
                understand how changes impact the entire system. This approach
                ensures that every modification enhances system coherence and
                functionality.
              </Text>
            </Box>
          </Flex>

          <Carousel
            img1={img1}
            img2={img2}
            img3={img3}
            img4={img4}
            img5={img5}
          />

          <Flex flexWrap="wrap" justifyContent="center" variant="container">
            {/* Deliverables */}
            <Box variant="containerTxt">
              <Heading as="h3" variant="catHeading">
                The End
              </Heading>
              <Heading as="h2" variant="bigTitleXS">
                Deliverables
              </Heading>
              <ul>
                <li>Design System Guidelines & Recommedations</li>
                <li>UX Strategy & Approach</li>
                <li>Documentation</li>
              </ul>
              <Heading as="h2" variant="bigTitleXS" mt={[3, 4, 5]}>
                Personal Note
              </Heading>
              <Text as="p">
                In large, global companies, it's crucial to recognize that
                change occurs incrementally and can be challenging to implement.
                Celebrating small victories is essential to maintain momentum
                and encourage ongoing progress.
              </Text>
              {/* <Heading as="h3" variant="catHeading" mt={[3, 4, 5]}>
              Out there
            </Heading>

            <Link
              variant="buttons.primary"
              href="https://bose-8230a6-af49cf35884748b3b2214c99d83.webflow.io/explore/at-home"
              target="_blank"
              rel="noopener norefferer"
              py={[3]}
              my={[3, 4]}
            >
              View prototype
            </Link> */}
            </Box>
          </Flex>
        </div>
        <CompanyProjects title="BOSE" />
        <ProjectTitleListing />
        <BackToTop />
        <Footer />
      </main>
    </Layout>
  );
}

export default ProjectBoseDS;
