import React, { FunctionComponent, useEffect, ReactElement } from "react";
import { Flex, Box, Heading } from "rebass";
import ReactDOM from "react-dom";
import styled from "styled-components";
import Close from "../icons/Close";

export interface ModalProps {
  isShown: boolean;
  hide: () => void;
  //modalContent: JSX.Element;
  modalContent?: ReactElement | null;
  headerText: string;
}

export const Modal: FunctionComponent<ModalProps> = ({
  isShown,
  hide,
  modalContent,
  headerText,
}) => {
  const onKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 27 && isShown) {
      hide();
    }
  };

  useEffect(() => {
    isShown
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
    document.addEventListener("keydown", onKeyDown, false);
    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, [isShown]);

  const modal = (
    <React.Fragment>
      <Box
        onClick={hide}
        sx={{
          position: "fixed",
          width: "100%",
          height: "100%",
          top: "0",
          left: "0",
          backgroundColor: "overlay",
          zIndex: "9999",
          cursor: "pointer",
        }}
      />
      <Wrapper
        aria-modal
        aria-labelledby={headerText}
        tabIndex={-1}
        role="dialog"
      >
        <Box
          p={[4, 5]}
          sx={{
            backgroundColor: "bigTitle",
            color: "background",
            width: ["100vw", "100vw", "50vw"],
            height: "100%",
            maxHeight: "100vh",
            margin: "auto",
            zIndex: "9999",
            overflowY: "auto",
            borderRadius: "2rem",
          }}
        >
          <Flex flexWrap="wrap">
            <Box
              width={[1 / 1]}
              variant="containerTxt"
              mx="auto"
              sx={{ position: "relative" }}
              mb={[0,0,0]}
              pt={[4,0]}
            >
              <Heading as="h2" variant="bigTitleXXS" sx={{ color: "inherit" }}>
                {headerText}
              </Heading>
              <Box
                sx={{
                  backgroundColor: "backgroundElevation",
                  position: "fixed",
                  top: "0",
                  right: "0",
                  color:"text",
                  borderRadius:"default",
                  width: ["5rem","5.5rem"],
                  height: ["5rem", "5.5rem"],
                }}
                mx={[3, 4]}
                mt={[3, 4]}
              >
                <CloseButton onClick={hide} aria-label="Close"><Close /></CloseButton>
              </Box>
            </Box>
          </Flex>
          <Box
            width={[1 / 1]}
            variant="containerTxt"
            mx="auto"
            sx={{ overflow: "auto" }}
          >
            {modalContent}
          </Box>
        </Box>
      </Wrapper>
    </React.Fragment>
  );

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99999;
  width: inherit;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
`;

const CloseButton = styled.button`
  font-size: 1rem;
  line-height:1;
  border: none;
  border-radius: 99px;
  width: 100%;
  height: 100%;
  background: none;
  justify-content: center;
  align-items: center;
  :hover {
    cursor: pointer;
  }
  text-align: center;
  color: inherit;
  font-weight: bold;
`;
